
import { defineComponent } from "vue";
import GameContainer from "@/components/GameContainer.vue";
import Slider from "@/components/Slider.vue";

export default defineComponent({
  name: "PrintableInfo",
  components: {Slider,GameContainer},
  data() {
    const sliderItems: any[] = [{image: 'https://framerusercontent.com/images/v8ZMFVkwXI2NW0Z7JdRI2lDkrDs.png'},
      {image: 'https://framerusercontent.com/images/noq06FcHC2JmGpfegO26pqzTr1I.png'},
      {image: 'https://framerusercontent.com/images/eO31spBUVCip59rRdJ9McHTOHs0.png'},
      {image: 'https://framerusercontent.com/images/yigNX31h18Uq4zjnKwNE3EHvCFw.png'}]
    return {
      sliderItems,
      testImage: 'https://framerusercontent.com/images/xjO2MpSWQs8XzUiqU5xb9jwxQ.png',
      testTitle: 'Children\'s doctor - Dentist'
    }
  },
})
